import React, { useContext, useState, createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const Context = createContext({
  showConfirmedModal: () => null,
  showAttentionModal: () => null,
});
export function useModalContext() {
  return useContext(Context);
}
let clearModalTimeout = null;

const defModalState = {
  state: 'confirmed', //confirmed | loading | attention
  title: 'def title',
  subtitle: 'def subtilte',
  onCancel: null,
  onConfirm: null,
  hideCancelButton: false,
  hideConfirmButton: false,
  cancelButtonText: 'Cancel',
  confirmButtonText: 'OK',
  onClose: () => '',
  allowOutsideClick: true,
};
function ModalProvider({ children }) {
  let [isOpen, setIsOpen] = useState(false);
  let [modal, setModal] = useState(defModalState);

  const closeModal = () => (modal.onClose(), setIsOpen(false));

  const showModal = (
    options = {
      title: 'loading title',
      subtitle: 'loading subtitle',
      onCancel: null,
      onConfirm: null,
      loading: { title: 'loading title', subtitle: 'loading subtitle', duration: 1000 },
      hideCancelButton: false,
      hideConfirmButton: false,
      confirmButtonText: '',
      onClose: () => '',
    },
    stateAfterLoading
  ) => {
    setIsOpen(true);
    if (!options.loading) return setModalFromOptions(stateAfterLoading);

    setModal((s) => ({
      ...s,
      state: 'loading',
      title: options.loading.title,
      subtitle: options.loading.subtitle,
      onCancel: () => null,
      onConfirm: () => null,
      hideCancelButton: true,
      hideConfirmButton: true,
      confirmButtonText: undefined,
      cancelButtonText: undefined,
      allowOutsideClick: true,
    }));

    if (clearModalTimeout !== null) clearTimeout(clearModalTimeout);

    clearModalTimeout = setTimeout(() => setModalFromOptions(stateAfterLoading), options.loading.duration);

    function setModalFromOptions(state) {
      setModal((s) => ({
        ...s,
        state,
        title: options.title,
        subtitle: options.subtitle,
        onCancel: () => options.onCancel && options.onCancel(),
        onConfirm: () => options.onConfirm && options.onConfirm(),
        hideCancelButton: !!options.hideCancelButton,
        hideConfirmButton: !!options.hideConfirmButton,
        cancelButtonText:
          options.cancelButtonText === undefined ? defModalState.cancelButtonText : options.cancelButtonText,
        confirmButtonText:
          options.confirmButtonText === undefined ? defModalState.confirmButtonText : options.confirmButtonText,
        onClose: options.onClose || s.onClose,
        allowOutsideClick:
          options.allowOutsideClick === undefined ? defModalState.allowOutsideClick : options.confirmButtonText,
      }));
    }
  };

  const showConfirmedModal = (options) => showModal(options, 'confirmed');
  const showAttentionModal = (options) => showModal(options, 'attention');

  const stateToView = {
    confirmed: ['/images/modal-confirm.png', ''],
    attention: ['/images/modal-attention.png', ''],
    loading: ['/images/modal-loader.png', 'modal-loader'],
  }[modal.state] || ['', ''];

  return (
    <Context.Provider value={{ showConfirmedModal, showAttentionModal }}>
      {children}
      {isOpen && (
        <div className="custom-popup cp" onClick={() => modal.allowOutsideClick && closeModal()}>
          <div className="container" onClick={(e) => e.stopPropagation()}>
            <div className="icon">
              <img src={stateToView[0]} className={stateToView[1]} />
            </div>
            {modal.title && <p className="title">{modal.title}</p>}
            {modal.subtitle && <p className="subtitle">{modal.subtitle}</p>}

            <div className="actions">
              {!modal.hideCancelButton && (
                <div className="button" onClick={() => (modal.onCancel(), closeModal())}>
                  {modal.cancelButtonText || 'No, annulla'}
                </div>
              )}
              {!modal.hideConfirmButton && (
                <div className="button button-confirm" onClick={() => (modal.onConfirm(), closeModal())}>
                  {modal.confirmButtonText || 'Ok'}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Context.Provider>
  );
}

export default ModalProvider;

export function useModal() {
  const { showConfirmedModal, showAttentionModal } = useModalContext();
  //Attention
  const { t } = useTranslation();
  return {
    showLoading(opts) {
      showConfirmedModal({
        hideCancelButton: true,
        hideConfirmButton: true,
        loading: { title: opts.title, subtitle: opts.subtitle, duration: 1000000000 },
      });
    },
    showSuccess(opts) {
      showConfirmedModal({
        hideCancelButton: true,
        title: t('popup.confirm'),
        ...opts,
        subtitle: opts.subtitle || '',
      });
    },

    showAttention(opts) {
      showAttentionModal({
        hideCancelButton: true,
        title: t('popup.errorValidation'),
        ...opts,
        subtitle: opts.subtitle || '',
      });
    },

    showQuestion(opts) {
      showAttentionModal({ ...opts, subtitle: opts.subtitle || '' });
    },
  };
}