import GoogleMapReact from 'google-map-react';
import React from 'react';

export default function SimpleMap() {
  const defaultProps = {
    center: {
      lat: 45.6049748,
      lng: 9.9330003,
    },
    zoom: 14,
  };

  const exampleMapStyles = [
    { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#e9e9e9' }, { lightness: 17 }] },
    { featureType: 'landscape', elementType: 'geometry', stylers: [{ color: '#f5f5f5' }, { lightness: 20 }] },
    { featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }, { lightness: 17 }] },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
    },
    { featureType: 'road.arterial', elementType: 'geometry', stylers: [{ color: '#ffffff' }, { lightness: 18 }] },
    { featureType: 'road.local', elementType: 'geometry', stylers: [{ color: '#ffffff' }, { lightness: 16 }] },
    { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#f5f5f5' }, { lightness: 21 }] },
    { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#dedede' }, { lightness: 21 }] },
    { elementType: 'labels.text.stroke', stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }] },
    { elementType: 'labels.text.fill', stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }] },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { featureType: 'transit', elementType: 'geometry', stylers: [{ color: '#f2f2f2' }, { lightness: 19 }] },
    { featureType: 'administrative', elementType: 'geometry.fill', stylers: [{ color: '#fefefe' }, { lightness: 20 }] },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
    },
  ];

  const AnyReactComponent = ({ text }) => (
    <div>
      <img src="images/marker.png" alt="" style={{ height: '20px', width: '20px' }} />
      <h4 style={{ width: 200 }}>Via A. De Gasperi 65 </h4>
      <h4 style={{ width: 200 }}>25030 Zocco d’Erbusco (BS) Italy</h4>
    </div>
  );

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBJdMZQc81S4ZVWjKEWNyp4qYYyuk9zQYc' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          styles: exampleMapStyles,
        }}
      >
        <AnyReactComponent
          lat={45.6049748}
          lng={9.9330003}
          text={'Via A. De Gasperi 65\n' + '\n' + '25030 Zocco d’Erbusco (BS) Italy'}
        />
      </GoogleMapReact>
    </div>
  );
}