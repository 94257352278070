import React from 'react';

import classnames from 'classnames';

import { usePagination, DOTS } from './usePagination';

const Pagination = (props) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, onPageClickScrollHref, className } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      <div className="pagination_section">
        <div className="pagination_item">
          <ul>
            <li className={currentPage == 0 ? 'prev disable' : 'prev'}>
              <a href="#" onClick={onPrevious} />
            </li>

            {paginationRange.map((pageNumber) => {
              if (pageNumber === DOTS) {
                return <li className="pagination-item dots">&#8230;</li>;
              }

              return (
                <li
                  style={{ cursor: 'pointer' }}
                  className={classnames('pagination-item', {
                    selected: pageNumber === currentPage,
                  })}
                  onClick={() => onPageChange(pageNumber - 1)}
                >
                  <a href={'#' + onPageClickScrollHref}>
                    {pageNumber == currentPage + 1 ? (
                      <b>
                        <u>{pageNumber}</u>
                      </b>
                    ) : (
                      pageNumber
                    )}
                  </a>
                </li>
              );
            })}

            <li className={(currentPage + 1) * pageSize >= totalCount ? 'next disable' : 'next'}>
              <a
                onClick={(e) => {
                  onNext();
                }}
                href="#"
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Pagination;