import React from 'react';

import RegisterForm from '../components/Auth/RegisterForm';

const Register = () => {
  return (
    <div className="main_content account_register">
      <RegisterForm />
    </div>
  );
};

export default Register;