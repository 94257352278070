import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';

import './styles.scss';

const OrderPlaced = () => {
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  const orderId = searchParams.get('id');
  const orderType = searchParams.get('type');

  return (
    <div className="main_content order-completed-page">
      {orderType === 'sample' ? (
        <>
          <h1 className="title">{t('orderCompleted.yourSampleOrderWasSuccessful')}</h1>
          <p className="success-text">
            {t('orderCompleted.sampleOrder')}{' '}
            {orderId ? (
              <>
                <b>
                  <Link to={'/order-detail/' + orderId + '/Ordine aperto'}>{orderId}</Link>
                </b>{' '}
              </>
            ) : null}
            {t('orderCompleted.sampleOrderBeingProcessedNotification')}
          </p>
        </>
      ) : (
        <>
          <h1 className="title">{t('orderCompleted.yourOrderWasSuccessful')}</h1>
          <p className="success-text">
            {t('orderCompleted.order')}{' '}
            {orderId ? (
              <>
                <b>
                  <Link to={'/order-detail/' + orderId + '/Ordine aperto'}>{orderId}</Link>
                </b>{' '}
              </>
            ) : null}
            {t('orderCompleted.orderBeingProcessedNotification')}
          </p>
        </>
      )}
      <div className="actions">
        <Link to="/dashboard-list">
          <PrimaryButton fullWidth>{t('orderCompleted.myOrders')}</PrimaryButton>
        </Link>
      </div>
    </div>
  );
};

export default OrderPlaced;