import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import classnames from 'classnames';

import './style.scss';

const Drawer = ({ title, content, footer, onClose }) => {
  const { t, i18n } = useTranslation();

  const [closing, setClosing] = useState(false);

  const handleClose = () => {
    setClosing(true);
    setTimeout(onClose, 300);
  };

  return (
    <div className={classnames({ 'drawer-shadow': true, closing: closing })}>
      <div className="drawer">
        <div className="header">
          <span className="title">{title}</span>
          <button className="close" onClick={handleClose}>
            {t('Annulla')}
          </button>
        </div>
        <div className="content">{content}</div>
        {footer ? <div className="footer">{footer}</div> : null}
      </div>
    </div>
  );
};

export default Drawer;