import React from 'react';

function Colorshow({ filterColor, colorList, handleColorSelect, activeColor, cid }) {
  return (
    <>
      {filterColor?.length != 0 && (
        <>
          {/*<div className='accordation_color_filter'> Colori standard</div>*/}
          <div className="color_section custom-scrollbar">
            {filterColor?.map((color, idx) => (
              <div
                key={color}
                className={`color_items ${color?.id == cid ? 'active_selecteed_color' : 'deactive_selecteed_color'}`}
                onClick={() =>
                  handleColorSelect(
                    `#${color?.hex?.replace('#', '')}`,
                    color?.inventcolorid,
                    color?.hex_image_url,
                    color.id
                  )
                }
              >
                <div className="color_img">
                  {color?.hex_image ? (
                    <img className="colorSchema" src={color.hex_image_url} />
                  ) : (
                    <div className="colorSchema" style={{ backgroundColor: `#${color?.hex?.replace('#', '')}` }}></div>
                  )}
                </div>
                <div className="color_info">
                  <span className="code">{color?.inventcolorid}</span>
                  <span className="desc">{color?.mel}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {/*<div className='accordation_color_filter'> Colori melange</div>*/}
      <div className="color_section custom-scrollbar">
        {colorList?.map((color, idx) => {
          return (
            <div
              key={color?.id}
              className={`color_items ${color?.id == cid ? 'active_selecteed_color' : 'deactive_selecteed_color'}`}
              onClick={() =>
                handleColorSelect(
                  `#${color?.hex?.replace('#', '')}`,
                  color?.inventcolorid,
                  color?.hex_image_url,
                  color.id
                )
              }
            >
              <div className="color_img">
                {color?.hex_image ? (
                  <img className="colorSchema" src={color.hex_image_url} />
                ) : (
                  <div className="colorSchema" style={{ backgroundColor: `#${color?.hex?.replace('#', '')}` }}></div>
                )}
              </div>
              <div className="color_info">
                <span className="code">{color?.inventcolorid}</span>
                <span className="desc">{color?.mel}</span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Colorshow;