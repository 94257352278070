import React from 'react';

import SampleImage from '../../../components/product/RequestSample/SampleImage';

import './PSample.scss';

const PSample = ({ item, withBorderBottom, RemoveCart }) => {
  const sample = JSON.parse(item.is_available_json)?.sample;

  return (
    <div className={`p-sample ${withBorderBottom ? 'with-border-bottom' : ''}`}>
      <SampleImage sampleCodiceArticolo={sample.codice_sample} configid={item.config_id} />
      <div className="details">
        <div className="title">{sample?.name}</div>
        {sample?.subProduct ? (
          <div>
            <div>{sample.subProduct.nome_articolo ?? sample.subProduct.description}</div>
          </div>
        ) : null}
        {RemoveCart ? (
          <div className="actions">
            <div className="delete_order">
              <a
                href="#"
                onClick={(e) => {
                  RemoveCart(item?.id);
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PSample;