import { React, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { getCertificateName } from '../../../constants/certificates';
import { useProductTotals } from '../../../hooks/useProductTotals';
import { useModal } from '../../../providers/ModalProvider';
import { useLoader } from '../../../utils/hooks';
import QuantitySelector from '../../Inputs/QuantitySelector/QuantitySelector';
import LangLink from '../../navigation/LangLink';
import AvailabilitySelector from '../../product/Components/AvailabilitySelector';
import CertificateIcon from '../../product/Components/CertificateIcon/CertificateIcon';
import ProductImage from '../../product/Components/ProductImage';
import { CUSTOM_PRODUCT_IMAGE_SRC } from '../../product/custom_product';

const Purchase = ({ item, updateCart, timer, setTimer, list, formatPriceForView, updateCartItem, RemoveCart }) => {
  const loader = useLoader({ height: 255 });
  const modal = useModal();
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(Number(item.quantity));

  const mainProduct = useMemo(
    () => ({ CERTIFICATESGROUP_CGK_19052: item.CERTIFICATESGROUP_CGK_19052, ...item.products[0] }),
    item
  );

  const {
    price,
    unitPrice,
    totalPrice,
    totalSupplement: totalSuppliment,
    isAvailableJson: is_available_json,
    setIsAvailableJson: SetIs_available_json,
    globalAvailable,
    available,
    setAvailable: Setavailable,
    isOption: isoption,
    supplement: suppliment,
    totalSupplement2: totalSupplement2,
    supplement2,
    certification,
    isLoading: loading,
    quantityAfterProductTotals: debouncedQuantity,
  } = useProductTotals({
    mainProduct: mainProduct,
    colorName: item.color_id,
    qty: quantity,
    productInitialPrice: parseFloat(item?.product_price),
    productInitialAvailable: JSON.parse(item.is_available_json)?.available,
    productInitialSupplement: JSON.parse(item.is_available_json)?.suppliment || 0,
    productInitialCertificate: item?.certificate,
    productInitialSupplement2: JSON.parse(item.is_available_json)?.suppliment2 || 0,
  });

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const olddata = JSON.parse(item.is_available_json);
    const newdata = { ...olddata, suppliment, suppliment2: supplement2, available: available };
    const stringifyed = JSON.stringify(newdata);

    axios
      .post(
        `/v1/update-cart/${item.id}`,
        {
          config_id: item?.config_id,
          color_id: item?.color_id,
          product_name: item?.product_name,
          product_price: price,
          suppliment: suppliment,
          CERTIFICATESGROUP_CGK_19052: item?.CERTIFICATESGROUP_CGK_19052,
          certificate: certification,
          suppliment2: supplement2,
          quantity: debouncedQuantity,
          client_id: item?.client_id,
          is_available_json: stringifyed,
          precise_integer: item?.precise_integer,
          avelphysical: available,
        },
        { signal }
      )
      .then((response) => {
        updateCartItem(response.data.result.data);
      })
      .catch((e) => !signal?.aborted && console.error(e));

    return () => {
      controller.abort();
    };
  }, [debouncedQuantity, available, certification]);

  if (loader.asJsx) return loader.asJsx;

  return (
    <div className="purchase-item">
      <div className="cart_details">
        <div className="product_img">
          <ProductImage
            activeimage={item?.hex_image_url}
            activeColor={item?.hex}
            product_t_images={item?.is_custom_product ? [] : item?.products['0']?.product_t_images}
            product_image={item?.is_custom_product ? CUSTOM_PRODUCT_IMAGE_SRC : item?.products['0']?.product_image}
          />
        </div>
        <div className="product_name">
          <LangLink to={`/${item.slug}/${item.products[0]?.product_id}`} className="cart_details_title">
            {item?.product_name}
          </LangLink>
          <div className="order_details_text">
            <p>{item?.products[0]?.composition}</p>
            <p>
              <span>Nm:</span>
              <span> {item?.products['0']?.nm}</span>
            </p>
            <p>
              <span>Ne:</span>
              <span> {item?.products['0']?.ne}</span>
            </p>
            {certification ? (
              <p>
                <b>{t('Certificazioni')}:</b>{' '}
                <span className="certificate">
                  {getCertificateName(certification)}
                  <CertificateIcon YARNTYPEID={certification} />
                </span>
              </p>
            ) : null}
            <p>
              <span>{t('finezza')}:</span>
              <span>{item?.products['0']?.finezza || 'NA'} </span>
            </p>
          </div>
        </div>
        <div className="delete_order mobile">
          <a
            href="#"
            onClick={(e) => {
              RemoveCart(item?.id);
            }}
          />
        </div>
        <div className="product_color">
          <div>
            <h4 className="cart_details_title">{t('color')}</h4>
            <p className="order_details_text">{item.color_id}</p>
          </div>
        </div>
        <div className="product_price">
          <h4 className="cart_details_title">{t('Prezzo un.')}</h4>
          <p className="order_details_text bolder">{formatPriceForView(unitPrice)}</p>
        </div>
        <div className="quantity-block">
          <h4 className="cart_details_title quantity">{t('Quantità')}</h4>
          <QuantitySelector QTY={quantity} updateQTY={setQuantity} small />
          {/* <p className="order_details_textd ">{item.quantity}</p> */}
          {parseInt(JSON.parse(item.is_available_json)?.isprecise) >= 1 ? (
            <>
              <div className="is-precise">
                <label>
                  <input type="checkbox" checked />
                  <span className="checkbox"></span>
                </label>
                <span>Rocche precise</span>
              </div>
              {/*<p className="order_details_textd ">
                <div class="mb-3">
                   <input type="text" onChange={(e)=>( updateCart(item,"isprecise",e.target.value) )}
                  <input
                    type="number"
                    style={{
                      border: 'none',
                      textAlign: 'center',
                      width: '-webkit-fill-available',
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      // console.log(`e:`, e, e.target.value);
                      clearTimeout(timer);
                      const newTimer = setTimeout(() => {
                        value <= 99999 && value >= 1 ? updateCart(item, 'isprecise', value) : '';
                      }, 500);

                      setTimer(newTimer);
                    }}
                    min={1}
                    max={1000}
                    defaultValue={parseInt(JSON.parse(item.is_available_json)?.isprecise)}
                    class="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                  />
                </div>{' '}
              </p>*/}
            </>
          ) : (
            ''
          )}
        </div>
        <div className="product_total">
          {suppliment ? (
            <div>
              <h4 className="cart_details_title supliment">{t('Supplemento')}:</h4>
              <p className="order_details_text bolder">{formatPriceForView(suppliment)}</p>
            </div>
          ) : null}
          <div>
            <h4 className="cart_details_title total">{t('Totale')}:</h4>
            <p className="order_details_text bolder">
              {loading ? <div className="loader"></div> : formatPriceForView(totalPrice)}
            </p>
          </div>
          <div className="delete_order desktop">
            <a
              href="#"
              onClick={(e) => {
                RemoveCart(item?.id);
              }}
            />
          </div>
        </div>
      </div>
      <div className="availability_options_order">
        <AvailabilitySelector
          QTY={quantity}
          globalAvailable={globalAvailable}
          available={available}
          Setavailable={Setavailable}
        />
      </div>
    </div>
  );
};

export default Purchase;