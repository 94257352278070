const lowercase = (obj) =>
    Object.entries(obj).reduce((acc, [key, value]) => ((acc[key.toLowerCase()] = value), acc), {});
const User = {
    getIvaAmount(user = {}) {
        return (lowercase(user).custgroup || '').toLowerCase() === 'c-naz' ? 0.22 : 0;
    },
    getCurrency(user = {}) {
        user = lowercase(user);
        if (user.currency === 'EUR') return 'EUR';
        if (user.currency === 'USD') return 'USD';
        return user.currency === '$' ? 'USD' : 'EUR';
    },
};

export default User;  