//import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual';

import { combineReducers } from 'redux';

import authReducer from './auth';
import cartReducer from './cart';
import compareReducer from './compare';
import filtersReducer from './filters';
import homeReducer from './home';
import pagesReducer from './pages';
// Import custom components
import productReducer from './products';
import serviceReducer from './service';
import settingsReducer from './settings';
import wishlistReducer from './wishlist';


const rootReducer = combineReducers({
    auth: authReducer,
    home: homeReducer,
    data: productReducer,
    cartList: cartReducer,
    filters: filtersReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    pages: pagesReducer,
    settings: settingsReducer,
    service: serviceReducer,
});

export default rootReducer;