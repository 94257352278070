import {
    SET_HOME_BRANDS,
    SET_HOME_FOOTER_CATEGORY,
    SET_HOME_MAIN_MENU,
    SET_HOME_SLIDER,
    SET_HOME_SIDEBAR_CATEGORIES,
    SET_HOME_DASHBOARD_MODULES,
    SET_HOME_DASHBOARD_MODULES_NEW,
    SET_OFFERS,
    GET_PRODUCT_HOME_PAGE_PRODUCT,
    SET_PRODUCT_HOME_PAGE_PRODUCT,
} from '../constants/ActionTypes';

const initialState = {
    sideBarMenus: [],
    sliders: [],
    mainMenu: [],
    dashboardModules: [],
    brands: [],
    footer_category: [],
    product_top: [],
    product_center: [],
    product_bottom: [],
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HOME_SIDEBAR_CATEGORIES:
            return { ...state, sideBarMenus: action.sideBarMenus };
        case SET_HOME_DASHBOARD_MODULES:
            return { ...state, dashboardModules: action.dashboardModules };
        case SET_HOME_DASHBOARD_MODULES_NEW:
            return { ...state, dashboardModulesnew: action.dashboardModules };
        case SET_HOME_SLIDER:
            return { ...state, sliders: action.sliders };
        case SET_HOME_MAIN_MENU:
            return { ...state, mainMenu: action.mainMenu };
        case SET_HOME_BRANDS:
            return { ...state, brands: action.brands };
        case SET_HOME_FOOTER_CATEGORY:
            return { ...state, footer_category: action.footer_category };
        case SET_OFFERS:
            return { ...state, offerlist: action.offerlist };
        case SET_PRODUCT_HOME_PAGE_PRODUCT:
            return { ...state, ...action.data };
        default:
            return state;
    }
};
export default homeReducer;  