export const getTranslated = (i18n, object, fields_by_language, default_field) => {
    const language = i18n.language;

    if (!object) {
        return null;
    }

    if (language in fields_by_language && fields_by_language[language] in object) {
        return object[fields_by_language[language]];
    }

    return default_field in object ? object[default_field] : null;
};

export const translateProblemType = (id) => {
    let name = '';

    switch (id) {
        //Inizio tipi di problemi ticket-online
        case 'DIP/RC - Scorrev':
            name = 'Scorrevolezza';
            break;
        case 'FIL - Gira':
            name = 'Filato che gira';
            break;
        case 'FIL - Grovigli':
            name = 'Grovigli';
            break;
        case 'FIL - Inquinam':
            name = 'Inquinamento';
            break;
        case 'FIL - Resistenza':
            name = 'Resistenza bassa';
            break;
        case 'FIL - Titolo <>':
            name = 'Titolo diverso';
            break;
        case 'SPEDIZ. eccesso':
            name = 'Spedito in eccesso';
            break;
        case 'TINT - Barrat':
            name = 'Barratura';
            break;
        case 'TINT - Fuori tono':
            name = 'Fuori tono';
            break;
        case 'TINT - Solid colore':
            name = 'Solidità colore';
            break;
        //Fine tipi di problemi ticket-online
        case 'CONF - Confezione':
            name = 'Problemi confezione';
            break;
        case 'DIP/RC - Densita roc':
            name = "Differenza densita' rocche";
            break;
        case 'DIP/RC - Paraff':
            name = 'Eccesso di paraffina';
            break;
        case 'DIP/RC - Rc sporche':
            name = 'Rocche sporche';
            break;
        case 'FIL - Asole':
            name = 'Asole';
            break;
        case 'FIL - barratura':
            name = 'barratura su greggio';
            break;
        case 'FIL - Componenti':
            name = 'Componenti errati';
            break;
        case 'FIL - Nodi':
            name = 'Nodi';
            break;
        case 'FIL - Peeling':
            name = 'Eccesso di pelosità';
            break;
        case 'FIL - Polvere':
            name = 'Accumulo di polvere sul guidafilo o in fase di lavorazione';
            break;
        case 'FIL - Regolarita':
            name = "Regolarita'";
            break;
        case 'FIL - Ritorcitura':
            name = "Problemi in ritorcitura'";
            break;
        case 'FIL - Rotti/Tagliati':
            name = "Fili rotti o Tagliati";
            break;
        case 'FIL - Torsione':
            name = "Torsione con filo errato";
            break;
        case 'FIL- Umidità':
            name = "troppo umido o troppo secco";
            break;
        case 'ORD - Err commerc':
            name = "Errore commerciale";
            break;
        case 'ORD - Ns richiesta':
            name = "Nostra richiesta";
            break;
        case 'ORD - Reso concordat':
            name = "Reso concordato con commerciale perché non serve piu'";
            break;
        case 'ORD -Annullato':
            name = "Ordine annullato";
            break;
        case 'ORD- errore cliente':
            name = "Errore cliente";
            break;
        case 'ORD-Ritardo':
            name = "Spedizione in ritardo";
            break;
        case 'RES - Annullato':
            name = "Ordine annullato";
            break;
        case 'RES - Asole':
            name = "Asole";
            break;
        case 'RES - Barratura':
            name = "barratura greggio";
            break;
        case 'RES - Barratura tint':
            name = "Barratura tintura";
            break;
        case 'RES - Componenti':
            name = "Componenti errati";
            break;
        case 'RES - Diff.colore':
            name = "Differenza coore tra rocca e rocca";
            break;
        case 'RES - eccesso':
            name = "spedito in eccesso";
            break;
        case 'RES - Err commerc':
            name = "Errore commerciale";
            break;
        case 'RES - Err commerc':
            name = "Errore commerciale";
            break;
        case 'RES - errore cliente':
            name = 'Errore cliente';
            break;
        case 'RES - Errore MAGA':
            name = 'Errore dovuto al Magazzino';
            break;
        case 'RES - Est/Int rocca':
            name = 'Esterno - Interno rocca';
            break;
        case 'RES - etichette':
            name = 'Errore confezione-etichettatura';
            break;
        case 'RES - Fuori tono':
            name = 'Fuori tono';
            break;
        case 'RES - Grovigli':
            name = 'Grovigli';
            break;
        case 'RES - Inquinam':
            name = 'Inquinamento';
            break;
        case 'RES - mano':
            name = 'Mano';
            break;
        case 'RES - Nodi':
            name = 'Nodi';
            break;
        case 'RES - Ns richiesta':
            name = 'Nostra richiesta';
            break;
        case 'RES - Polvere':
            name = 'Accumulo di polvere sul guidafilo o in fase di lavorazione';
            break;
        case 'RES - Regolarità':
            name = "Regolarita'";
            break;
        case 'RES - Resistenza':
            name = "Resistenza bassa";
            break;
        case 'RES - Reso concordat':
            name = "Reso concordato con commerciale perché non serve piu'";
            break;
        case 'RES - Ritardo':
            name = "Spedizione in ritardo";
            break;
        case 'RES - Ritorcitura':
            name = "Ritorcitura";
            break;
        case 'RES - Rotti/tagliati':
            name = "Fili rotti o Tagliati";
            break;
        case 'RES - scorevolezza':
            name = "scorrevolezza";
            break;
        case 'RES - Solid colore':
            name = "Solidità colore";
            break;
        case 'RES - Solid sfreg':
            name = "Solidità sfregamento";
            break;
        case 'RES - Stabilità':
            name = "Filato che gira";
            break;
        case 'RES - Titolo':
            name = "Titolo diverso";
            break;
        case 'RES - Torsione':
            name = "Torsione con filo errato";
            break;
        case 'RES - Umidità':
            name = "troppo umido o troppo secco";
            break;
        case 'SPEDIZ Errore MAGA':
            name = "Errore dovuto al Magazzino";
            break;
        case 'SPEDIZ- etichette':
            name = "Errore confezione-etichettatura";
            break;
        case 'SPEDIZ. eccesso':
            name = "spedito in eccesso";
            break;
        case 'SPEDIZ. eccesso':
            name = "spedito in eccesso";
            break;
        case 'TINT - Diff.colore':
            name = "Differenza colore tra rocca e rocca";
            break;
        case 'TINT - Est/Int rocca':
            name = "Esterno - Interno rocca";
            break;
        case 'TINT - mano':
            name = "Mano";
            break;
        case 'TINT - Solid sfreg':
            name = "Solidita' sfregamento";
            break;
        default:
            break;
    }

    return name;
};  