import { useEffect, useState } from 'react';

import axios from 'axios';

export const useRegistrationFormDetails = () => {
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);

    axios
      .get('/v1/registration-form-details', {
        signal,
      })
      .then((res) => {
        setDetails(res?.data || {});
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => setIsLoading(true));

    return () => abortController.abort();
  }, []);

  return {
    isLoading,
    details,
  };
};