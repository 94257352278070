import {
    SET_USER_SIGNUP_SUCCESS,
    LET_USER_LOGOUT,
    USER_LOGIN_DATA,
    UPDATE_PRODUCTS_IN_CART_COUNTER,
  } from '../constants/ActionTypes';
  
  const initialState = {
    isLoggedIn: !!localStorage.getItem('token'),
    userDataDetail: JSON.parse(localStorage.getItem('userDataDetail'), true),
    agentDataDetail: JSON.parse(localStorage.getItem('agentDataDetail'), true),
    productsInCartCounter: 0,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_USER_SIGNUP_SUCCESS:
        return { ...state, userData: action.userData, isLoggedIn: true };
      case LET_USER_LOGOUT:
        return initialState;
      case USER_LOGIN_DATA:
        return {
          ...state,
          userDataDetail: action.userdetailData,
          ...(action.userdetailData.is_agent ? { agentDataDetail: action.userdetailData } : {}),
        };
      case UPDATE_PRODUCTS_IN_CART_COUNTER:
        return {
          ...state,
          productsInCartCounter: action.productsInCartCounter,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;  