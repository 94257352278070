import React, { useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { UPDATE_PRODUCTS_IN_CART_COUNTER } from '../../../constants/ActionTypes';
import { useSampleProducts } from '../../../hooks/useSampleProducts';
import { useModal } from '../../../providers/ModalProvider';
import { handleMultiCartTypeError } from '../../../utils/errorHandling';
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton';
import Drawer from '../../layout/Drawer/Drawer';
import Sample from './Sample';
import './styles.scss';

const RequestSample = ({ onClose, productsForFondo, details }) => {
  const userDataDetail = useSelector((state) => state?.auth?.userDataDetail);
  const dispatch = useDispatch();
  const modal = useModal();
  const { t } = useTranslation();
  let duplicate_collezione = false; 
  const [selectedSamples, setSelectedSamples] = useState([]);
  const { isLoading, sampleProducts } = useSampleProducts({
    configid: details?.configid,
    productsForFondoCono: productsForFondo,
  });
  let cart = [];
  
  const handleSampleSelection = (checked, sampleProductId, subProductId) => {
    setSelectedSamples((prevState) => {
      if (checked) {
        return {
          ...prevState,
          [sampleProductId]: {
            sampleProductId,
            selectedSubProducts: [
              ...(prevState[sampleProductId]?.selectedSubProducts || []),
              ...(subProductId ? [subProductId] : []),
            ],
          },
        };
      }
      if (subProductId) {
        const updatedSubProducts = (prevState[sampleProductId]?.selectedSubProducts || []).filter(
          (id) => id !== subProductId
        );

        if (updatedSubProducts.length) {
          return {
            ...prevState,
            [sampleProductId]: {
              ...prevState[sampleProductId],
              selectedSubProducts: updatedSubProducts,
            },
          };
        }
      }
      delete prevState[sampleProductId];
      return {
        ...prevState,
      };
    });
  };

  const getcartlistUser = async () => {
    try {
      const response = await axios.post('/v1/cart-list', {
        client_id: parseInt(userDataDetail?.id),
      });
      
      return response.data.result.data.carts;
    } catch (error) {
      console.log(error);
      return null;
    }
  };


  const addSampleToCart = async () => {
    try {
      const responses = await Promise.all(
        Object.entries(selectedSamples).map(async ([key, value]) => {
          const sample = sampleProducts.find((s) => s.codice_articolo === value.sampleProductId);
          const cart = await getcartlistUser();
          let collezione = '';
          let pezzo_campione = '';
          let sku_campione ='';
          if(sample.subProducts){
            collezione = sample.subProducts[0].configid; // configid
            pezzo_campione = sample.codice_articolo;
            sku_campione = selectedSamples[key].selectedSubProducts[0];
          }
          else{
            collezione = sampleProducts[4].subProducts[0].configid; // prendo il configid di un telino/fondo cono
            pezzo_campione = selectedSamples[key].sampleProductId;
            sku_campione='';
          }
          console.log('pezzo_campione:',pezzo_campione);

          if(pezzo_campione=="fondo_cono"){
            pezzo_campione="FONDO CONO";
          }
          else if(pezzo_campione=="tello" || pezzo_campione=="TELINO"){
            pezzo_campione="Telo";
          }
          else if(pezzo_campione.startsWith('BOOK PE') || pezzo_campione.startsWith('CC BOOK PE')){
            pezzo_campione="BOOK PE 2025";
          }
          else if(pezzo_campione.startsWith('BOOK AI') || pezzo_campione.startsWith('CC BOOK AI') ){
            pezzo_campione="BOOK AI 2025";
          }
          else if(pezzo_campione.startsWith('CC')){
            pezzo_campione="CARTELLA COLORI";
          }

          cart.forEach(item => {
            if(sku_campione!='' && (item.product_name== "FONDO CONO" || item.product_name== "Telo" )){
                let dati_json_subproduct = JSON.parse(item.is_available_json);
                if(dati_json_subproduct){
                  let codice_subprodotto = dati_json_subproduct.sample.subProduct.codice_sub_sample;
                  if(item.config_id === collezione && item.product_name === pezzo_campione && sku_campione===codice_subprodotto){
                    duplicate_collezione = true;
                    //console.log('Il campione '+item.product_name+' '+item.slug+' è già presente nel carrello');
                    modal.showAttention({
                      title: t('popup.productSampleDuplicateTitle'),
                      subtitle: t('popup.productSampleDuplicateSubtitle'),
                      confirmButtonText: t('popup.confermo_prodotto_duplicate'),
                      onConfirm: onClose,
                    })
                  }
                }
            }
            else if(item.config_id === collezione && item.product_name === pezzo_campione) {
              duplicate_collezione = true;
              //console.log('Trovato un elemento con config_id corrispondente:', item);
              console.log('Il campione '+item.product_name+' '+item.slug+' è già presente nel carrello');
              modal.showAttention({
                title: t('popup.productSampleDuplicateTitle'),
                subtitle: t('popup.productSampleDuplicateSubtitle'),
                confirmButtonText: t('popup.confermo_prodotto_duplicate'),
                onConfirm: onClose,
              })
            }
          });
          if(duplicate_collezione){
            return;
          }
       
          if (!sample) {
            return;
          }

          const addToCardData = {
            config_id: details?.configid,
            product_name: sample.title,
            quantity: sample.qty,
            client_id: userDataDetail.id,
            product_type: 'sample',
          };

          const is_available_json = {
            ItemId: details?.codice_articolo,
            sample: {
              codice_sample: sample.codice_articolo,
              name: sample.title,
            },
          };

          if (value.selectedSubProducts.length > 0) {
            return await Promise.all(
              value.selectedSubProducts.map(async (selectedSubProduct) => {
                const subProduct = sample.subProducts.find((s) => s.codice_articolo === selectedSubProduct);
                if (!subProduct) {
                  return;
                }

                return await axios.post('/v1/add-to-cart', {
                  ...addToCardData,
                  is_available_json: JSON.stringify({
                    ...is_available_json,
                    sample: {
                      ...is_available_json.sample,
                      subProduct: {
                        codice_sub_sample: subProduct.codice_articolo,
                        description: subProduct.description,
                        nome_articolo: subProduct.nome_articolo,
                        nm: subProduct.nm,
                        ne: subProduct.ne,
                        finezza: subProduct.finezza,
                      },
                    },
                  }),
                });
              })
            );
          }

          return axios.post('/v1/add-to-cart', {
            ...addToCardData,
            is_available_json: JSON.stringify(is_available_json),
          });
        })
      );

      if(responses[0] && duplicate_collezione==false){
        const max = Math.max(...responses.flat().map((r) => r.data.result.data?.cartQuantity || 0));
        dispatch({
          type: UPDATE_PRODUCTS_IN_CART_COUNTER,
          productsInCartCounter: max,
        });

        modal.showSuccess({
          subtitle: t('popup.productAdded'),
          confirmButtonText: t('ok_got_it'),
          onConfirm: onClose,
        });
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.result?.type === 'multicart_type_error') {
        handleMultiCartTypeError(userDataDetail, modal, onClose, dispatch, t);
      } else if (error?.response?.data?.result?.type === 'error_reached_sample_limit') {
        modal.showAttention({
          subtitle: t('10_sample_limit'),
          confirmButtonText: 'OK',
          onCancel: () => onClose(),
        });
      } else {
        alert(error?.response?.data?.message || 'Something went wrong');
      }
    }
  };

  return (
    <Drawer
      onClose={onClose}
      title={t('requestSample.Richiedi campionatura')}
      content={
        <div className="samples-form">
          {isLoading ? (
            <div className="loading-samples-block">
              <div className="loader"></div>
            </div>
          ) : (
            sampleProducts.map((sample) => {
              return (
                <Sample
                  key={sample.codice_articolo}
                  sample={sample}
                  configid={details?.configid}
                  handleSampleSelection={handleSampleSelection}
                  isSelected={!!selectedSamples[sample.codice_articolo]}
                  selectedSubProducts={selectedSamples[sample.codice_articolo]?.selectedSubProducts || []}
                />
              );
            })
          )}
        </div>
      }
      footer={
        <PrimaryButton fullWidth disabled={Object.keys(selectedSamples).length === 0} onClick={addSampleToCart}>
          {t('requestSample.Richiedi campioni')}
        </PrimaryButton>
      }
    />
  );
};

export default RequestSample;
