import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';

export const useGetUserDetails = () => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const [isLoading, setIsLoading] = useState(false);
  const [productsInCartCounter, setProductsInCartCounter] = useState(0);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      if (!dataUser?.token) {
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.get('/v1/get-user-details', {
          headers: {
            Authorization: `Bearer ${dataUser?.token}`,
          },
          signal,
        });

        setProductsInCartCounter(response?.data?.result?.data?.cartQuantity || 0);
        setAddresses(response?.data?.result?.data?.addresses || []);
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, [dataUser]);

  return {
    isLoading,
    productsInCartCounter,
    addresses,
  };
};