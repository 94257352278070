import axios from 'axios';

import { UPDATE_PRODUCTS_IN_CART_COUNTER } from '../constants/ActionTypes';

export const handleMultiCartTypeError = (userDataDetail, modal, onCloseCallback, dispatch, t) => {
  const clearCart = () => {
    modal.showLoading({
      allowOutsideClick: false,
    });

    axios
      .post(`/v1/all-remove-from-cart`, {
        client_id: userDataDetail.id,
      })
      .then(() => {
        modal.showSuccess({});
        dispatch({
          type: UPDATE_PRODUCTS_IN_CART_COUNTER,
          productsInCartCounter: 0,
        });
      });
  };

  modal.showAttention({
    subtitle: t('cant_mix_products_in_cart'),
    confirmButtonText: 'OK',
    cancelButtonText: t('CLEAR CART'),
    hideCancelButton: false,
    onConfirm: () => onCloseCallback(),
    onCancel: () => clearCart(),
  });
};