import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { useTranslatableNavigator } from '../../utils/hooks';
import { getTranslated } from '../../utils/translations';
import LangLink from '../navigation/LangLink';

import { productSearchParamsMap } from './product';

const Product_component = (props) => {
  const [result, SetNews] = useState({ ...props.data });

  const randomString = () => {
    return Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);
  };

  const { i18n } = useTranslation();

  return (
    <>
      <LangLink key={randomString()} to={`/${result?.slug}/${result?.id}`} style={{ position: 'relative' }}>
        <div className="product_item">
          <img
            src={result?.product_image}
            alt="Immagine"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/artic_06.jpg';
            }}
          />
          <div className="product_data" style={{ position: 'static', bottom: '0' }}>
            <span className="tag">{_.capitalize(result?.p_name)}</span>
            <p className="name">
              {result?.perc_1?.replaceAll('.0', '')}
              {result.perc_1 ? '% ' : ''}
              {getTranslated(i18n, result, { en: 'fibra_1_en', it: 'fibra_1 ' }, 'fibra_1')}
              {result.fibra_2 ? ' - ' : ''}
              {result?.perc_2 != 0 ? (
                <>
                  {result?.perc_2?.replaceAll('.0', '')}
                  {result.perc_2 ? '% ' : ''}
                </>
              ) : (
                ''
              )}
              {getTranslated(i18n, result, { en: 'fibra_2_en', it: 'fibra_2 ' }, 'fibra_2')}
              {result.fibra_3 ? ' - ' : ''}
              {result?.perc_3 != 0 ? (
                <>
                  {result?.perc_3?.replaceAll('.0', '')}
                  {result.perc_3 ? '% ' : ''}
                </>
              ) : (
                ''
              )}
              {getTranslated(i18n, result, { en: 'fibra_3_en', it: 'fibra_3 ' }, 'fibra_3')}
            </p>
            <div className="item_color">
              <p className="count">
                <span>+{result?.colori_in_cartella}</span>
              </p>
              <p className="color">
                <span className="red" />
                <span className="brown" />
                <span className="blue" />
              </p>
            </div>
          </div>
        </div>
      </LangLink>
    </>
  );
};
export default Product_component;