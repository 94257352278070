export function formatPriceForView(numberOrStringNumber, currency = 'EUR') {
    let symbol = currency === 'EUR' ? '€' : '$';
    return formatNumberForView(numberOrStringNumber) + ' ' + symbol;
}

export function formatQuantityForView(numberOrStringNumber) {
    return formatNumberForView(numberOrStringNumber) + ' ' + 'Kg';
}

export default function formatNumberForView(numberOrStringNumber, options = {}) {
    let number = Number(numberOrStringNumber);

    if (isNaN(number)) return '---';

    let lang = 'it';
    return number.toLocaleString(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2, ...options });
}  