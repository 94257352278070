import { GET_SERVICE, SET_SERVICE } from '../constants/ActionTypes';

const initialState = {
  service: {},
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERVICE:
      return { ...state, service: action.service };
    case GET_SERVICE:
      return { ...state, service: action.service };
    default:
      return state;
  }
};
export default serviceReducer;