import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SecondaryButton from '../../Buttons/SecondaryButton/SecondaryButton';
import ClientsList from '../components/ClientsList';
import Container from '../components/Container';
import InfoBlock from '../components/InfoBlock';
import PersonalDataBlock from '../components/PersonalDataBlock';
import TabsComponent from '../components/TabsComponent';

import './styles.scss';

const AgentDashboard = () => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const { t } = useTranslation();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userDataDetail');
    localStorage.removeItem('agentDataDetail');
    window.location.href = '/';
  };

  return (
    <div className="main_content agent-dashboard">
      <Container>
        <div className="agent-dashboard__heading">
          <h1 className="agent-dashboard__heading__title">{t('agent.agent-dashboard')}</h1>
          <SecondaryButton onClick={logout}>{t('Logout')}</SecondaryButton>
        </div>
        <PersonalDataBlock>
          <InfoBlock title={t('dashboard.nome')} value={dataUser?.NAMEALIAS} />
          <InfoBlock title={t('dashboard.cognome')} value={dataUser?.LASTNAME} />
          <InfoBlock title={t('dashboard.email')} value={dataUser?.email} />
          <InfoBlock title={t('dashboard.telefono')} value={dataUser?.mobile_no} />
        </PersonalDataBlock>
      </Container>
      <TabsComponent useContainerForHeader tabs={[{ name: t('agent.lista-clienti'), content: <ClientsList /> }]} />
    </div>
  );
};

export default AgentDashboard;