import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';
import swal from 'sweetalert';

import { useModal } from '../../providers/ModalProvider';
import { isCustomProduct } from '../../utils/customProduct';
import { useLoader } from '../../utils/hooks';
import { CUSTOM_PRODUCT_IMAGE_SRC } from '../product/custom_product';

require('form-data');

const Ticket_Create = (lodemore) => {
  const [tab, setTab] = useState('Ticket');
  const [ticketData, setTicketData] = useState({
    orderNumber: '', //numero ordine
    data: '', //data ordine
    NumeroBagno: '',
    lotto: '',
    tipo: '', //tipo di non conformità*
    problema: '', // problem_type
    Nome: '', //nome articolo*
    ItemId: '', //id articolo*
    colore: '', //codice colore*
    qty: 1, //quantità difettosa
    qtySpedita: null,
    attachment: '',
  });
  const [editData, setEditData] = useState({});
  const [SalesTable, setSalesTable] = useState({});
  const [product, setProducts] = useState([]);
  const [article, setArticle] = useState(null);
  const params = useParams();
  const { t } = useTranslation();
  const loader = useLoader({ height: 546 });
  const modal = useModal();

  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const handleChange = (e) => {
    const data = { ...ticketData };
    data[e.target.name] = e.target.value;
    setTicketData(data);
  };

  const handleChangeOrder = (e) => {
    const data = { ...ticketData };
    data[e.target.name] = e.target.value;
    setTicketData(data);

    getOrderDetail();
  };

  const getOrderDetail = (orderId) => {
    if (orderId || ticketData?.orderNumber) {
      loader.watchPromise(
        axios
          .post(
            '/v1/order-detail',
            {
              customerid: dataUser.ACCOUNTNUM,
              orderid: orderId ? orderId : ticketData?.orderNumber,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser?.token}`,
              },
            }
          )
          .then((res) => {
            setSalesTable([...res?.data?.result?.data['SalesTable']]);
            getproducts(res?.data?.result?.data['SalesTable']);
          })
          .catch((error) => console.log('error', error))
      );
    }
  };

  const getproducts = (array) => {
    const dataToPost = [];
    const urls = [];
    let val = [];
    let i = 0;
    let supplementisun = 0;
    array?.map((data) => {
      data?.SalesLine?.map((val) => {
        dataToPost.push({
          id: val.ItemId,
          InventColorId: val?.InventDim[0].InventColorId,
        });
        urls.push('v1/product_detail/data');
      });
    });

    const axiosPostRequests = urls.map((url, index) => {
      return axios.post(url, dataToPost[index]).catch(() => ({}));
    });

    Promise.all(axiosPostRequests)
      .then((responses) => {
        // Handle the responses for each request
        responses.forEach((response) => {
          val[i] = response?.data?.result?.data || {};
          i = 1 + i;
          setProducts({ ...val });
        });
      })
      .catch((error) => {
        // Handle any errors that may occur during the requests
        console.error('An error occurred:', error);
      });
  };

  const populateTicket = (e, sale, indexSale, indexDim) => {
    const data = { ...ticketData };

    const dataOrdine = new Date(SalesTable[0]?.BFSalesDate);

    data.data = dataOrdine ? dataOrdine.toLocaleDateString() : null;
    data.NumeroBagno = sale?.InventDim[indexDim]?.InventBatchId;
    data.lotto = sale?.InventTransId;
    data.tipo = null;
    data.Nome = sale.Name;
    data.colore = sale?.InventDim[indexDim]?.InventColorId;
    data.ItemId = sale.ItemId;
    data.ConfigId = sale?.InventDim[indexDim]?.ConfigId;
    data.qtySpedita = sale?.DeliveredIntotal;

    setTicketData(data);
    setArticle(indexSale + '-' + indexDim);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (ticketData.ItemId.trim() == '') {
      modal.showAttention({
        subtitle: t('Si prega di selezionare una riga ordine'),
      });
      return false;
    }

    let data = new FormData();

    data.append('customerid', dataUser.ACCOUNTNUM);
    data.append('orderNumber', ticketData.orderNumber);
    data.append('data', ticketData.data);
    data.append('NumeroBagno', ticketData.NumeroBagno);
    data.append('lotto', ticketData.lotto);
    data.append('tipo', ticketData.tipo);
    data.append('problema', ticketData.problema);
    data.append('Nome', ticketData.Nome);
    data.append('ItemId', ticketData.ItemId);
    data.append('colore', ticketData.colore);
    data.append('qty', ticketData.qty);
    data.append('attachment', ticketData.attachment);
    data.append('ConfigId', ticketData.ConfigId);

    axios({
      method: 'post',
      url: 'v1/insert-ticket/' + params?.customer_id,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${dataUser?.token}`,
      },
    })
      .then(function (response) {
        modal.showSuccess({
          subtitle: t('Il ticket è stato creato correttamente'),
          onConfirm: () => {
            window.location.href = '/dashboard-list';
          },
        });
      })
      .catch(function (err) {
        if (err.response) {
          modal.showAttention({
            subtitle: err.response.data.message.replace('.,', '\n'),
          });
        }
      });
  };

  const quantitaSpedita = (quantitaOrdinata, quantitaDaSpedire) => {
    let q1 = Number(quantitaOrdinata);
    let q2 = Number(quantitaDaSpedire);

    if (isNaN(q1)) q1 = 0;
    if (isNaN(q2)) q2 = 0;

    let lang = 'it';
    return (q1 - q2).toLocaleString(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    if (params.order_id) {
      var data = { ...ticketData };

      data.orderNumber = params.order_id;
      setTicketData(data);
      getOrderDetail(params.order_id);
    }
  }, [params?.customer_id]);

  return (
    <>
      <div className="main_content utenti_create">
        <div className="utenti_form_section">
          <div className="ticket_form_wrapper container">
            <div className="utenti_form_title">
              <h2>{t('create_ticket')}</h2>
              <p>{t('ticket_head_text')}</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="info_block user_info">
                <div className="form-input user_field">
                  <label>{t('order_number') + '*'}</label>
                  <input
                    type="text"
                    placeholder={t('order_number') + '*'}
                    name="orderNumber"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleChangeOrder(e)}
                    value={ticketData?.orderNumber}
                    readOnly={params?.order_id}
                    required
                  />
                  <div className="ticket_articles_list">
                    {loader.asJsx || (
                      <>
                        {Array.isArray(SalesTable)
                          ? SalesTable[0]?.SalesLine?.map((sale, i) => {
                              return sale.SalesStatus == 2 || sale.SalesStatus == 3 || sale.SalesStatus == 4 ? (
                                <div className="ticket_availability_section">
                                  {sale?.InventDim?.map((dim, j) => {
                                    return (
                                      <a
                                        role="button"
                                        onClick={(e) => populateTicket(e, sale, i, j)}
                                        className={article == i + '-' + j ? 'checked' : ''}
                                      >
                                        <div className="ticket_available_item" key={i}>
                                          <div className="item_availability_detail">
                                            <div className="available_img">
                                              {isCustomProduct(dataUser, dim.ConfigId) ? (
                                                <img
                                                  className="colorSchema"
                                                  style={{ objectFit: 'cover' }}
                                                  src={CUSTOM_PRODUCT_IMAGE_SRC}
                                                />
                                              ) : product[i]?.color?.hex_image ? (
                                                <img className="colorSchema" src={product[i]?.color.hex_image_url} />
                                              ) : (
                                                <div
                                                  className="colorSchema"
                                                  style={{
                                                    backgroundColor: `#${product[i]?.color?.hex?.replace('#', '')}`,
                                                    border: `${
                                                      product[i]?.color?.hex ? '1px solid rgb(42, 45, 46)' : 0
                                                    }`,
                                                  }}
                                                ></div>
                                              )}
                                            </div>
                                            <div className="availab_item">
                                              <div className="availab_item_name">
                                                <p className="article_name">
                                                  <strong>{product[i]?.nome_articolo || dim.ConfigId} </strong>
                                                </p>
                                                <p>
                                                  <span className="order_name">
                                                    {product[i]?.composition || sale?.Name}
                                                  </span>
                                                  {product[i]?.nm ? (
                                                    <span className="order_num">
                                                      {' '}
                                                      | Nm {product[i]?.nm} | Ne {product[i]?.ne} | Finezza
                                                      {product[i]?.finezza}{' '}
                                                    </span>
                                                  ) : (
                                                    ''
                                                  )}
                                                </p>
                                                <p className="article_name">
                                                  <strong>{t('colore')}:</strong>
                                                  <p>{dim.InventColorId}</p>
                                                </p>
                                              </div>
                                              <div className="availab_item_name availab_item_detail availab_item_color">
                                                <p className="article_name">
                                                  <strong>{t('Numero Bagno')}:</strong> {dim.InventBatchId}
                                                </p>
                                                <p className="article_name">
                                                  <strong>{t('Qtà spedita')}:</strong>{' '}
                                                  {quantitaSpedita(sale?.DeliveredIntotal)}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    );
                                  })}
                                </div>
                              ) : null;
                            })
                          : null}
                      </>
                    )}
                  </div>
                </div>
                <div className="form-input user_field">
                  <label>{t('Quantità') + '*'}</label>
                  <input
                    type="number"
                    placeholder={t('defective_quantity') + '*'}
                    name="qty"
                    onChange={(e) => handleChange(e)}
                    step="0.01"
                    max={ticketData?.qtySpedita}
                    required
                  />
                </div>
                <div className="form-input user_field">
                  <label>{t('problem_type') + '*'}</label>
                  <select name="problema" onChange={(e) => handleChange(e)} value={ticketData?.problema} required>
                    <option value="">{t('problem_type') + '*'}</option>
                    <option value="DIP/RC - Scorrev">{t('Scorrevolezza')}</option>
                    <option value="FIL - Gira">{t('Filato che gira')}</option>
                    <option value="FIL - Grovigli">{t('Grovigli')}</option>
                    <option value="FIL - Inquinam">{t('Inquinamento')}</option>
                    <option value="FIL - Resistenza">{t('Resistenza bassa')}</option>
                    <option value="FIL - Titolo <>">{t('Titolo diverso')}</option>
                    <option value="SPEDIZ. eccesso">{t('Spedito in eccesso')}</option>
                    <option value="TINT - Barrat">{t('Barratura')}</option>
                    <option value="TINT - Fuori tono">{t('Fuori tono')}</option>
                    <option value="TINT - Solid colore">{t('Solidità colore')}</option>
                  </select>
                </div>
                <div className="form-input user_field">
                  <label>{t('Note')}</label>
                  <textarea name="tipo" placeholder={t('Note')} onChange={(e) => handleChange(e)}></textarea>
                </div>
              </div>
              <div className="action">
                <button type="submit">{t('create_ticket')}</button>
              </div>
              <input type="hidden" name="data" value={ticketData?.data} required />
              <input type="hidden" name="NumeroBagno" value={ticketData?.NumeroBagno} required />
              <input type="hidden" name="lotto" value={ticketData?.lotto} required />
              <input type="hidden" name="Nome" value={ticketData?.Nome} required />
              <input type="hidden" name="colore" value={ticketData?.colore} required />
            </form>
            <div className="create_link">
              <p>
                <Link to="/dashboard-list">{t('Annulla')}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Ticket_Create;