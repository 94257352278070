import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    INCREMENT_QTY,
    DECREMENT_QTY,
    UPDATE_WITH_DELIVERY_SLOTS,
    RESET_THE_CART,
    APPLY_COUPON_DATA,
    SET_CART_DATA,
    SET_CART_DATA_FULL,
    FETCH_CART_DATA,
  } from '../constants/ActionTypes';
  
  const cartlist = JSON.parse(localStorage.getItem('cartlist'), true);
  
  const initialState = {
    cart: cartlist && cartlist.length >= 1 ? cartlist : [],
    cartLoader: false,
    coupon_discount: 0,
  };
  const initialStatereset = {
    cart: [],
    cartLoader: false,
    coupon_discount: 0,
  };
  
  export default function cartReducer(state = initialState, action) {
    switch (action.type) {
      case ADD_TO_CART:
        const productId = action.product.id;
        // console.log(productId);
        if (state.cart.findIndex((product) => product.id === productId) !== -1) {
          const cart = state.cart.reduce((cartAcc, product) => {
            if (product.id === productId) {
              cartAcc.push({
                ...product,
                cart_quantity: product.cart_quantity + 1,
                // sum: ((product.price * product.discount) / 100) * (product.qty + 1),
                sum: 0,
              }); // Increment qty
            } else {
              localStorage.setItem('cartlist', JSON.stringify(product));
              cartAcc.push(product);
            }
  
            return cartAcc;
          }, []);
          localStorage.setItem('cartlist', JSON.stringify(cart));
          return { ...state, cart };
        }
  
        const cartlist = [
          ...state.cart,
          {
            ...action.product,
            cart_quantity: action.qty,
            // sum: ((action.product.price * action.product.discount) / 100) * action.qty,
            sum: 0,
          },
        ];
        localStorage.setItem('cartlist', JSON.stringify(cartlist));
  
        return {
          ...state,
          cart: cartlist,
        };
  
      case DECREMENT_QTY:
        const productId_d = action.product.id;
        if (state.cart.findIndex((product) => product.id === productId_d) !== -1) {
          const cart = state.cart.reduce((cartAcc, product) => {
            if (product.id === productId_d) {
              cartAcc.push({
                ...product,
                cart_quantity: product.cart_quantity - 1,
                // sum: ((product.price * product.discount) / 100) * (product.qty + 1),
                sum: 0,
              }); // Increment qty
            } else {
              cartAcc.push(product);
            }
  
            return cartAcc;
          }, []);
          localStorage.setItem('cartlist', JSON.stringify(cart));
          return { ...state, cart };
        }
  
        const cartlistdown = [
          ...state.cart,
          {
            ...action.product,
            cart_quantity: action.qty,
            // sum: ((action.product.price * action.product.discount) / 100) * action.qty,
            sum: 0,
          },
        ];
        localStorage.setItem('cartlist', JSON.stringify(cartlistdown));
  
        return {
          ...state,
          cart: cartlistdown,
        };
  
      case REMOVE_FROM_CART:
        const remove_porduct = state.cart.filter((item) => {
          console.log(item.id !== action.product_id);
          return item.id !== action.product_id;
        });
        localStorage.setItem('cartlist', JSON.stringify(remove_porduct));
        return {
          ...state,
          cart: remove_porduct,
        };
  
      case UPDATE_WITH_DELIVERY_SLOTS:
        const updatedCart = state.cart;
        let indexOfCurrentProduct = updatedCart.findIndex((product) => product.id === action.product.id);
        if (indexOfCurrentProduct !== -1) {
          updatedCart[indexOfCurrentProduct] = action.product;
        }
        return {
          ...state,
          cart: updatedCart,
        };
  
      case APPLY_COUPON_DATA:
        return {
          ...state,
          coupon_discount: action.coupon_discount,
        };
  
      case FETCH_CART_DATA:
        return {
          ...state,
          cartLoader: true,
        };
  
      case SET_CART_DATA:
        return {
          ...state,
          cartLoader: false,
          cart: action.data,
        };
  
      case SET_CART_DATA_FULL:
        return {
          ...state,
          cartLoader: false,
          cart_full: action.data,
        };
  
      case RESET_THE_CART:
        localStorage.setItem('cartlist', JSON.stringify([]));
        return initialStatereset;
      default:
    }
  
    return state;
  }  