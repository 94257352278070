import React from 'react';

const Loader = ({ style = {} }) => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', ...style }}>
      {' '}
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
