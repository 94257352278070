import React, { createContext, useState } from 'react';

export const contactPaths = {
  en: '/en/contact',
  it: '/it/contatti',
};

export const productPaths = {
  en: '/en/products',
  it: '/it/prodotti',
};

export const customProductPaths = {
  en: '/en/custom-products',
  it: '/it/prodotti-personalizzati',
};

export const newsPaths = {
  en: '/en/news',
  it: '/it/news',
};

export const cartPaths = {
  en: '/en/cart',
  it: '/it/carrello',
};

export const checkoutPaths = {
  en: '/en/checkout',
  it: '/it/cassa',
};

export const orderPlacedPaths = {
  en: '/en/order-placed',
  it: '/it/ordine-effettuato',
};

export const registerPaths = {
  en: '/en/register',
  it: '/it/registrati',
};

const translatablePaths = [
  contactPaths,
  productPaths,
  customProductPaths,
  newsPaths,
  cartPaths,
  orderPlacedPaths,
  checkoutPaths,
  registerPaths,
];

export const TranslatedSlugContext = createContext();

export const TranslatedSlugProvider = ({ children }) => {
  const [currentSlug, setCurrentSlug] = useState('');
  const [slugTranslations, setSlugTranslations] = useState({});

  const updateSlug = (slug, slugTranslations) => {
    setCurrentSlug(slug);
    setSlugTranslations(slugTranslations);
  };

  const clearSlug = () => {
    setCurrentSlug('');
    setSlugTranslations({});
  };

  const getTranslatedSlug = (currentPath, currentLang, newLang) => {
    const translatedPathObject = translatablePaths.find((pathObject) => {
      for (const key in pathObject) {
        if (pathObject[key] === currentPath) {
          return true;
        }
      }

      return false;
    });

    if (translatedPathObject && newLang in translatedPathObject) {
      return translatedPathObject[newLang];
    }

    let pathParts = window.location.pathname.split('/');

    if (pathParts[1] === currentLang) {
      pathParts[1] = newLang;
    }

    pathParts = pathParts.map((part) => {
      if (part === currentSlug && newLang in slugTranslations) {
        return slugTranslations[newLang];
      }

      return part;
    });

    return pathParts.join('/');
  };

  return (
    <TranslatedSlugContext.Provider value={{ updateSlug, clearSlug, getTranslatedSlug }}>
      {children}
    </TranslatedSlugContext.Provider>
  );
};