import React from 'react';

import classnames from 'classnames';

import './styles.scss';

const QuantitySelector = ({ QTY, updateQTY, small = false }) => {
  return (
    <div className={classnames({ 'product-qty-selector': true, small: small })}>
      <span
        className="minus"
        style={{ userSelect: 'none' }}
        onClick={(e) => {
          if (QTY >= 2) updateQTY(Math.round(QTY - 1));
        }}
      >
        -
      </span>
      <input
        defaultValue={1}
        value={QTY}
        onChange={(e) => {
          updateQTY(e.target.value);
        }}
        onInput={({ target: el }) => {
          if (el.value !== '') {
            if (parseInt(el.value) < parseInt(el.min)) {
              el.value = el.min;
            }
            if (parseInt(el.value) > parseInt(el.max)) {
              el.value = el.max;
            }
          }
        }}
        type="number"
        min={1}
        max={1000}
        style={{ userSelect: 'none' }}
      />
      <span
        className="add"
        style={{ userSelect: 'none' }}
        onClick={(e) => {
          updateQTY(parseFloat(QTY) + 1);
        }}
      >
        +
      </span>
    </div>
  );
};

export default QuantitySelector;
