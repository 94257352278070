import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useSearchParams, useParams } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import axios from 'axios';

import LangLink from '../navigation/LangLink';

const News = (props) => {
  const [category, SetCategory] = useState([]);
  const [years, setYears] = useState([]);
  const [news, SetNews] = useState([]);
  const [category_id, SetCategoryId] = useState(null);
  const [year, SetYear] = useState(2023);
  const [page, SetPage] = useState(1);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (page == 1) {
      document.getElementById('home_page_class').classList.remove('home_page');
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    axios.get('/v1/blog-filters').then((response) => {
      SetCategory(response?.data?.result?.data?.available_categories || []);
      setYears(response?.data?.result?.data?.available_years || []);
    });

    newslist(false);
  }, [category_id, year]);

  const newslist = (lodemore) => {
    axios
      .get('/v1/blog', {
        params: { page: page, category_id: category_id, year: year },
      })
      .then((response) => {
        if (lodemore === true) {
          SetNews([...news, ...response.data.result.data.data]);
          SetPage(page + 1);
        } else {
          SetNews([...response.data.result.data.data]);
          SetPage(1);
        }
      });
  };
  return (
    <>
      <div className="main_content blog_page">
        <div className="container title_section">
          <div className="main_title">
            <div className="title">
              <h2>{t('tutte_le_novita_dal')}</h2>
            </div>
          </div>
          <div className="scopri_anche_section">
            <div className="scopri_anche_item">
              {category.map((category, index) => (
                <div
                  key={category.id}
                  onClick={(e) => {
                    e.preventDefault();
                    SetPage(1);
                    SetCategoryId(category.id);
                  }}
                  className={`scopri_anche_box  ${category_id == category.id ? 'active' : ''}`}
                >
                  <a key={category.id + 'l'} href="javascript:void(0);">
                    {category.name}
                  </a>
                </div>
              ))}
            </div>
            <div className="year_dropdown">
              <select
                class="form-select form-select-lg mb-3"
                defaultValue={year}
                onChange={(event) => SetYear(event.target.value)}
              >
                {years.map((y) => (
                  <option value={y}>{y}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="container blog_wrapper">
          {news.map((news, index) => (
            <div className="news_item">
              <LangLink className="news_wrapper" to={'/news/' + news.slug}>
                <img src={news.blog_image_url} alt="Digital" />
                <div className="news_data">
                  <p className="tag">
                    <span className="date">
                      <Moment format="D MMM YYYY">{news.created_at}</Moment>
                    </span>
                    <span>{t('eco_news_eventi')}</span>
                  </p>
                  <p className="name">{news.title}</p>
                </div>
              </LangLink>
            </div>
          ))}
        </div>
        <div className="container more_section">
          <div className="link">
            <button
              onClick={(e) => {
                newslist(true);
              }}
            >
              {t('mostra_altre_news')}{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;