import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { USER_LOGIN_DATA } from '../../../../constants/ActionTypes';
import { useUsersByAgent } from '../../../../hooks/useUsersByAgent';
import { productPaths } from '../../../../providers/TranslatedSlugProvider';
import { getTranslated } from '../../../../utils/translations';
import PrimaryButton from '../../../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../Buttons/SecondaryButton/SecondaryButton';
import SearchInput from '../../../Inputs/SearchInput';
import Loader from '../../../Loader';
import Pagination from '../../Pagination';
import Container from '../Container';
import InfoBlock from '../InfoBlock';

import './styles.scss';

const ClientsList = () => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoading, rows, page, setPage, filters, updateFilter, perPage, setPerPage, resetFilter, totalCount } =
    useUsersByAgent({});

  const handleViewClientClick = async (client, redirect = null) => {
    const response = await axios.post(
      '/v1/client-token-by-agent',
      {
        customerid: client.ACCOUNTNUM,
      },
      {
        headers: {
          Authorization: `Bearer ${dataUser?.token}`,
        },
      }
    );

    localStorage.setItem('userDataDetail', JSON.stringify(response?.data?.result?.data));
    dispatch({
      type: USER_LOGIN_DATA,
      userdetailData: response?.data?.result?.data,
    });

    if (redirect) {
      navigate(redirect);
    }
  };

  return (
    <div className="clients-list">
      <Container>
        <div className="clients-filter">
          <div className="left">
            <SearchInput
              placeholder={t('agent.search-client')}
              value={filters.search}
              onChange={(event) => {
                setPage(1);
                updateFilter('search', event.target.value);
              }}
            />
            <SecondaryButton onClick={resetFilter}>{t('common.filter_reset')}</SecondaryButton>
          </div>
          <div className="right">
            <div className="per-page">
              <span>{t('client_number')}</span>
              <select
                name="per_page"
                id="per_page"
                value={perPage}
                onChange={(event) => setPerPage(event.target.value)}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="clients-list__loader-wrapper">
            <Loader />
          </div>
        ) : (
          <div className="clients-list__list-block">
            <div className="clients-list__list-block__client-item visible-on-tablet">
              <div className="clients-list__list-block__client-item__info">
                <div className="clients-list__list-block__client-item__info__head">
                  {t('client-list.business-name')}
                </div>
                <div className="clients-list__list-block__client-item__info__head">{t('client-list.iva')}</div>
                <div className="clients-list__list-block__client-item__info__head">{t('client-list.email')}</div>
                <div className="clients-list__list-block__client-item__info__head">{t('client-list.phone')}</div>
              </div>
            </div>
            {rows.length > 0 ? (
              rows.map((i) => (
                <div className="clients-list__list-block__client-item">
                  <div className="clients-list__list-block__client-item__info">
                    <InfoBlock
                      title={t('client-list.business-name')}
                      value={i.business_name}
                      hideTitleOnTablet
                      normalValueWeightOnTablet
                    />
                    <InfoBlock
                      title={t('client-list.iva')}
                      value={i.vat_number}
                      hideTitleOnTablet
                      normalValueWeightOnTablet
                    />
                    <InfoBlock
                      title={t('client-list.email')}
                      value={i.email}
                      hideTitleOnTablet
                      normalValueWeightOnTablet
                    />
                    <InfoBlock
                      title={t('client-list.phone')}
                      value={i.PHONE}
                      hideTitleOnTablet
                      normalValueWeightOnTablet
                    />
                  </div>
                  <div className="clients-list__list-block__client-item__actions">
                    <SecondaryButton onClick={() => handleViewClientClick(i)}>{t('client-list.view')}</SecondaryButton>
                    <PrimaryButton
                      fullWidth
                      onClick={() =>
                        handleViewClientClick(i, getTranslated(i18n, productPaths, { en: 'en', it: 'it' }, 'it'))
                      }
                    >
                      {t('client-list.create-order')}
                    </PrimaryButton>
                  </div>
                </div>
              ))
            ) : (
              <div className="clients-list__list-block__client-item text-center">{t('agent.no-client-found')}</div>
            )}
          </div>
        )}
      </Container>
      {!(page === 1 && rows.length < perPage) ? (
        <div className="clients-list__pagination-block">
          <Pagination
            className="pagination-bar"
            currentPage={page - 1} // Why should we add 1?
            totalCount={totalCount}
            pageSize={perPage}
            onPageChange={(page) => setPage(page + 1)} // Why should we minus 1?
            onPageClickScrollHref="orders-table"
          />
        </div>
      ) : null}
    </div>
  );
};

export default ClientsList;