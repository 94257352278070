import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';

export const useCustomProducts = ({}) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const [isLoading, setIsLoading] = useState(false);
  const [customProducts, setCustomProducts] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);

    axios
      .post(
        '/v1/custom-products',
        {},
        {
          headers: {
            Authorization: `Bearer ${dataUser.token}`,
          },
          signal,
        }
      )
      .then((res) => {
        setCustomProducts(res?.data?.result?.data || []);
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => setIsLoading(false));

    return () => abortController.abort();
  }, []);

  return {
    isLoading,
    customProducts,
  };
};