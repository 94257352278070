import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useSearchParams, useParams } from 'react-router-dom';

import axios from 'axios';

import { useLoader } from '../../utils/hooks';
import Slider_cms from '../layouts/home/slider';

import NewsSlider from './news_slider';

const News_Details = (props) => {
  const [news, SetNews] = useState('');
  const { t, i18n } = useTranslation();
  const loader = useLoader({ height: '90vh' });

  const params = useParams();
  const slug = params.slug;

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    loader.watchPromise(
      axios.get('/v1/blog-detail?slug=' + slug).then((response) => {
        SetNews(response.data.result.data);
      })
    );
  }, [slug]);

  return (
    <div className="main_content blog_detail pitti">
      <div className="blog-content">
        {loader.asJsx || (
          <>
            <div className="title_section">
              <p>
                <Moment format="D MMM YYYY">{news.blog_date}</Moment> | {news?.category?.name}
              </p>
              <h2>{news?.title}</h2>
              <h4>{news?.subtitle}</h4>
            </div>

            {news.blog_base_image_url ? (
              <div className="image">
                <img src={news.blog_base_image_url} alt="" />
              </div>
            ) : null}

            <div dangerouslySetInnerHTML={{ __html: news.description }}></div>

            {news?.blog_image_url && news.blog_image_url.length > 0 ? (
              <div className="seller_section container">
                <Slider_cms images={news.blog_image_url || []} />
              </div>
            ) : null}
          </>
        )}
        <NewsSlider title={t('scopri_altre_news')} />
      </div>
    </div>
  );
};

export default News_Details;