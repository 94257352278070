import React from 'react';

import classnames from 'classnames';

import './styles.scss';

const InfoBlock = ({ title, value, normalValueWeightOnTablet = false, hideTitleOnTablet = false, style }) => {
  return (
    <div className="info-block" style={style}>
      <div className={classnames({ 'info-block__title': true, 'hide-on-tablet': hideTitleOnTablet })}>{title}</div>
      <div className={classnames({ 'info-block__value': true, 'normal-weight-on-tablet': normalValueWeightOnTablet })}>
        {value || '-'}
      </div>
    </div>
  );
};

export default InfoBlock;