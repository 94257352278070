import { React } from 'react';
import { useTranslation } from 'react-i18next';

const AvailabilitySelector = ({ QTY, globalAvailable, available, Setavailable }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="product_info">
      <h5 className="details_title">{t('Seleziona disponibilità')}*</h5>
      {(QTY >= 1 && globalAvailable) == 'option' ? (
        <>
          <label>
            <input type="checkbox" onClick={() => Setavailable('Multibagno')} checked={available === 'Multibagno'} />
            <span className="checkbox" />
            {t('Multibagno')}
          </label>
          <label>
            <input type="checkbox" onClick={() => Setavailable('on demand')} checked={available === 'on demand'} />
            <span className="checkbox" />
            {t('on demand')}
          </label>
        </>
      ) : (
        ''
      )}
      {globalAvailable != 'option' ? (
        <>
          {' '}
          {['Multibagno', 'on demand', 'Bagno unico'].includes(available) ? (
            <p style={{ display: 'inline' }}>
              {t('Disponibilità')} {available == 'on demand' ? '' : t('immediata')} - {t(available)}{' '}
            </p>
          ) : (
            <p>{t('Le opzioni di disponibilità saranno visibili una volta definita la quantità.')}</p>
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default AvailabilitySelector;