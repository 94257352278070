import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const Table = ({ data, children, columns }) => {
  const { t } = useTranslation();

  return (
    <div className="filmar-table">
      <table>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default Table;