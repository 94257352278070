import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './styles.scss';

const PersonalData = ({ children }) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);
  const { t } = useTranslation();

  return (
    <div className="personal-data">
      <div className="personal-data__heading">
        <h3 className="personal-data__heading__title">{t('Dati personali')}</h3>
        <Link to={'/profile-manage/' + dataUser.id} className="personal-data__heading__link">
          {t('Richiedi modifica')}
        </Link>
      </div>
      <div className="personal-data__details">{children}</div>
    </div>
  );
};

export default PersonalData;