import { React, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import axios from 'axios';

import '../assets/style2.css';
import { USER_LOGIN_DATA } from '../constants/ActionTypes';
import { useModal } from '../providers/ModalProvider';
import { cartPaths, productPaths, TranslatedSlugContext } from '../providers/TranslatedSlugProvider';
import { getTranslated } from '../utils/translations';

import LoggedInAsClientStatus from './dashboard_list/components/LoggedInAsClientStatus';

function Layout({ children }) {
  const slugContext = useContext(TranslatedSlugContext);

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [menu, SetMenu] = useState([]);
  const [footer, SetFooter] = useState([]);
  const [toggleMenu, SetToggleMenu] = useState(false);
  const [subMenu, SetSubMenu] = useState(0);
  const [socialMedia, SetsocialMedia] = useState([]);
  const [search, SetSearch] = useState(false);
  const [innerWidth, SetinnerWidth] = useState(window.innerWidth);
  const [searchParams, setSearchParams] = useSearchParams();
  const q_string = searchParams.get('q');
  const [q, setQ] = useState('');
  const [searchList, SetSearchList] = useState('');
  const { t, i18n } = useTranslation();
  const userDataDetail = useSelector((state) => state?.auth?.userDataDetail);
  const agentDataDetail = useSelector((state) => state?.auth?.agentDataDetail);
  const productsInCartCounter = useSelector((state) => state?.auth?.productsInCartCounter);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [privacy_policy, Setprivacy_policy] = useState(0);
  // const { navigate } = useTranslatableNavigator();
  const active = localStorage.getItem('menu_url');

  const location = useLocation();

  const toggleIcon = () => {
    SetToggleMenu(!toggleMenu);
  };
  const randomString = () => {
    return Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);
  };

  const toggleSubMenu = (e, id) => {
    e.target.classList.toggle('active');
    document.querySelector('.submenu0').classList.toggle('active');
    SetSubMenu(id);
  };

  const checkpermison = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'v1/sub-user/me',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + userDataDetail?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        dispatch({
          type: USER_LOGIN_DATA,
          userdetailData: { ...userDataDetail, ...response.data.result.data },
        });
        localStorage.setItem('userDataDetail', JSON.stringify({ ...userDataDetail, ...response.data.result.data }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios.get('/v1/social-media').then((response) => {
      if (isNaN(response.data.result.data)) SetsocialMedia([...response.data.result.data]);
    });

    axios.get('/v1/get-menu-list?type=1').then((response) => {
      if (isNaN(response.data.result.data)) SetMenu([...response.data.result.data]);
    });

    axios.get('/v1/get-menu-list?type=2').then((response) => {
      if (isNaN(response.data.result.data)) SetFooter([...response.data.result.data]);
    });

    if (typeof q_string === 'string' || q_string instanceof String) {
      SetSearch(true);
      setQ(q_string);
    }
    if (userDataDetail?.parent_id != 0) {
      checkpermison();
    }
  }, []);

  const modal = useModal();
  const NewsLatter = () => {
    {
      if (privacy_policy) {
        axios
          .post('/v1/newsletter_add', {
            name: name,
            surname: surname,
            email: email,
          })
          .then(function (response) {
            modal.showSuccess({ subtitle: t('keep-in-touch-form.successfully-submitted') });
            Setprivacy_policy(0);
            setEmail('');
            setName('');
            setSurname('');
            document.getElementById('newslatyer').reset();
          })
          .catch(function (err) {
            if (err?.response?.data?.message) {
              modal.showAttention({ subtitle: err.response.data.message });
            }
            setErrors(err?.response?.data?.result?.errors || {});
          });
      } else Setprivacy_policy(false);
    }
  };

  const handleLangChange = (event) => {
    const currentLang = localStorage.getItem('lang');
    const newLang = event.target.value;

    localStorage.setItem('lang', newLang);
    window.location.href = slugContext.getTranslatedSlug(location.pathname, currentLang, newLang);
  };

  const getFormattedMenuLink = (k) => {
    const translatedSlug = getTranslated(i18n, k, { en: 'slug_en', it: 'slug_it ' }, 'slug_it');
    if (translatedSlug && k.cms_id) {
      return `/${i18n.language}/page/${translatedSlug}`.replace(/\/\//g, '/');
    }

    const translatedLink = getTranslated(i18n, k, { en: 'link_en', it: 'link_it ' }, 'link_it');
    if (translatedLink) {
      return `/${i18n.language}/${translatedLink}`.replace(/\/\//g, '/');
    }

    return '';
  };

  return (
    <>
      <header className="sticky">
        <div id="home_page_class">
          <div className="header_section">
            <div className="header_wrapper">
              <div className="nav_section">
                <Link onClick={() => localStorage.setItem('menu_url', 'home')} className="logo_link blue_logo" to="/">
                  <img className="logo" src="/images/logo_filmar.svg" alt="Logo" />
                </Link>
                <Link onClick={() => localStorage.setItem('menu_url', 'home')} className="logo_link white_logo" to="/">
                  <img className="logo" src="/images/white_logo.png" alt="Logo" />
                </Link>
                <nav className={toggleMenu ? 'active' : ''}>
                  <div className="mobile_logo">
                    <a className="logo_link" href="/">
                      <img className="logo" src="/images/logo_filmar.svg" alt="Logo" />
                    </a>
                    <span className="close_icon " onClick={toggleIcon}></span>
                  </div>
                  <div className="mobile_navber">
                    <div className="search">
                      <input
                        onChange={(e) => {
                          setQ(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            window.location.href =
                              getTranslated(i18n, productPaths, { en: 'en', it: 'it' }, 'it') + '?q=' + q;
                          }
                        }}
                        type="text"
                        defaultValue={q}
                        name="search"
                        placeholder={t('cerca_prodotto')}
                      />

                      <span
                        className="icon_search"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          window.location.href =
                            getTranslated(i18n, productPaths, { en: 'en', it: 'it' }, 'it') + '/?q=' + q;
                        }}
                      ></span>
                    </div>
                    {userDataDetail?.id ? (
                      <div className="user_after_login">
                        <div className="header_items">
                          <Link className="login" to="/dashboard-list">
                            {userDataDetail?.business_name}
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="user_section">
                      {/*<div className="header_items"><a href="#" className="wishlist">Wishlist</a></div>*/}
                      {userDataDetail?.id ? (
                        <div className="header_items">
                          <Link className="cart" to={getTranslated(i18n, cartPaths, { en: 'en', it: 'it' }, 'it')}>
                            {t('Carrello')}
                            <span className="cart-icon">
                              <i className="fas fa-shopping-cart"></i>
                              {productsInCartCounter ? <span className="counter">{productsInCartCounter}</span> : null}
                            </span>
                          </Link>
                        </div>
                      ) : (
                        <div className="header_items">
                          <Link className="login" to="/login">
                            Login
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <ul className="submenu0 -- ">
                    {menu.map((k) => {
                      return userDataDetail?.view_catalog === false && k.menu_name == 'Filati' ? (
                        <></>
                      ) : (
                        <li
                          key={randomString()}
                          id={k.menu_name + 'header'}
                          style={{ display: 'block' }}
                          className={`level0 ${Object.keys(k.sub_menulist).length != 0 ? 'parent' : ''}`}
                          onClick={(e) => toggleSubMenu(e, subMenu == k.id ? 0 : k.id)}
                        >
                          <a
                            key={randomString()}
                            className={`list-group-item ${active == k.menu_name && 'active'}`}
                            onClick={(e) => {
                              // e.preventDefault();
                              // navigate('products');
                              localStorage.setItem('menu_url', k.menu_name);
                            }}
                            href={getFormattedMenuLink(k)}
                          >
                            {' '}
                            {k.menu_name}
                          </a>

                          {Object.keys(k.sub_menulist).length != 0 ? (
                            <div className={`submenu ${subMenu == k.id ? 'active' : ''}`}>
                              <ul className="submenu_list">
                                {Object.entries(k.sub_menulist).map((item, i) => {
                                  return (
                                    <li key={randomString()} className="submenu_item text-dark">
                                      <a
                                        key={randomString()}
                                        className={`text-dark list-group-item ${
                                          active == item[1].menu_name && 'active'
                                        }`}
                                        onClick={() => localStorage.setItem('menu_url', item[1].menu_name)}
                                        href={getFormattedMenuLink(item[1])}
                                      >
                                        {' '}
                                        {item[1].menu_name}{' '}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            ''
                          )}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="menu_items country_dropdown">
                    <div className="">
                      <div className=" country_dropdown">
                        <select
                          style={{ border: 'none' }}
                          defaultValue={localStorage.getItem('lang') || 'it'}
                          onChange={handleLangChange}
                        >
                          <option className="lang_option" value="it">
                            IT
                          </option>
                          <option className="lang_option" value="en">
                            EN
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="header_links">
                <div className="header_links">
                  <div className="header_items country_dropdown">
                    <select defaultValue={localStorage.getItem('lang') || 'it'} onChange={handleLangChange}>
                      <option className="lang_option" value="it">
                        IT
                      </option>
                      <option className="lang_option" value="en">
                        EN
                      </option>
                    </select>
                  </div>
                </div>
                <div className="header_items search_field">
                  <a
                    href="javascript:void(0)"
                    className="search"
                    onClick={(e) => {
                      SetSearch(!search);
                    }}
                  >
                    {t('common.search')}
                  </a>
                </div>
                {search ? (
                  <div className="search_box mobile_hide">
                    <div className="search_input">
                      <input
                        onChange={(e) => {
                          setQ(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            window.location.href =
                              getTranslated(i18n, productPaths, { en: 'en', it: 'it' }, 'it') + '?q=' + q;
                          }
                        }}
                        type="text"
                        defaultValue={q}
                        name="search"
                        placeholder={t('cerca_prodotto')}
                      />
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          window.location.href =
                            getTranslated(i18n, productPaths, { en: 'en', it: 'it' }, 'it') + '?q=' + q;
                        }}
                      />
                    </div>
                    <div className="close_btn">
                      <a
                        href="javascript:void(0)"
                        onClick={(e) => {
                          SetSearch(!search);
                          setQ('');
                        }}
                      >
                        Close
                        {/*<img src="/images/close_search.png" alt="close_search"/>*/}
                      </a>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {/*<div className="header_items wishlist_section">*/}
                {/*    <a href="#" className="wishlist">{t("common.wishlist")}</a>*/}
                {/*</div>*/}
                {userDataDetail?.id ? (
                  <>
                    <div className="header_items login_section bg_theme">
                      <Link className="login username_header" style={{ whiteSpace: 'nowrap' }} to="/dashboard-list">
                        {agentDataDetail?.id
                          ? agentDataDetail?.NAMEALIAS || agentDataDetail?.business_name
                          : userDataDetail?.NAMEALIAS || userDataDetail?.business_name}
                      </Link>
                    </div>
                    <div className="header_items cart_section bg_theme">
                      <Link className="cart" to={getTranslated(i18n, cartPaths, { en: 'en', it: 'it' }, 'it')}>
                        {t('Carrello')}
                        <span className="cart-icon">
                          <i className="fas fa-shopping-cart"></i>
                          {productsInCartCounter ? <span className="counter">{productsInCartCounter}</span> : null}
                        </span>
                      </Link>
                    </div>
                  </>
                ) : (
                  <div className="header_items">
                    <Link className="login" to="/login">
                      {t('common.login')}
                    </Link>
                  </div>
                )}

                <div className="header_items toggle_section" onClick={toggleIcon}>
                  <a href="#" className="toggle"></a>
                </div>
              </div>
            </div>
            {agentDataDetail && agentDataDetail?.id !== userDataDetail?.id ? <LoggedInAsClientStatus /> : null}
          </div>
        </div>
      </header>

      {children}

      <footer>
        <div className="footer_section">
          <div className="footer_wrapper">
            <div className="footer_data">
              <div className="footer_links">
                <div className="address">
                  <img src="/images/footer-logo.png" alt="Footer Logo" />
                  <p className="tag_line">{t('leader_di_mercato_nella_produzione')}</p>
                  <div className="location">
                    <p>{t('via_a_de_gasperi_65')}</p>
                    <p>25030 Zocco d’Erbusco (BS) Italy</p>
                  </div>
                  <div className="company">
                    <p>Filmar S.P.A</p>
                    <p>{t('societa_soggetta_footer')}</p>
                  </div>
                  <div className="contect">
                    <p>
                      <a href="tel:+39 030 776700">+39 030 776700</a>
                    </p>
                    <p>
                      <a href="mailto:filmar@filmar.it">filmar@filmar.it</a>
                    </p>
                  </div>
                  <div className="social">
                    <ul>
                      <li>
                        <a href={socialMedia[0]?.facebook_link} target="_blank" className="facebook" />
                      </li>
                      <li>
                        <a href={socialMedia[0]?.instagram_link} target="_blank" className="instagram" />
                      </li>
                      <li>
                        <a href={socialMedia[0]?.linkedin_link} target="_blank" className="youtube" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="pages_link">
                  <ul>
                    {/* <li>
                                        <Link className={`list-group-item ${active == "products" && 'active'}`} onClick={() => localStorage.setItem('menu_url','products')} to="products">Collezioni</Link>
                                </li> */}

                    {footer
                      .filter((k) => !(userDataDetail?.view_catalog === false && k.menu_name == 'Filati'))
                      .map((k, key) => {
                        return (
                          <li key={key}>
                            <a
                              key={randomString()}
                              id={k.menu_name + 'footer'}
                              style={{ display: 'block' }}
                              className={`list-group-item ${active == k.menu_name && 'active'}`}
                              onClick={() => localStorage.setItem('menu_url', k.menu_name)}
                              href={getFormattedMenuLink(k)}
                            >
                              {' '}
                              {k.menu_name}{' '}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="newsletter">
                  <h3>{t('rimaniamo_in_contatto')}</h3>
                  <p className="tag_line">{t('siamo_a_tua_completa_disposizione')} </p>
                  <form action="#" method="POST" id="newslatyer">
                    <div className="name-block">
                      <div>
                        <input
                          type="name"
                          placeholder={t('keep-in-touch-form.name-required')}
                          defaultValue={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {errors && errors.name ? <div className="error">{errors.name[0]}</div> : null}
                      </div>
                      <div>
                        <input
                          type="surname"
                          placeholder={t('keep-in-touch-form.surname-required')}
                          defaultValue={surname}
                          onChange={(e) => setSurname(e.target.value)}
                        />
                        {errors && errors.surname ? <div className="error">{errors.surname[0]}</div> : null}
                      </div>
                    </div>
                    <div className="email">
                      <input
                        type="email"
                        name="newsletter"
                        placeholder={t('keep-in-touch-form.email-required')}
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors && errors.email ? <div className="error">{errors.email[0]}</div> : null}
                      <button
                        type="button"
                        onClick={() => {
                          NewsLatter();
                        }}
                        className="action subscribe"
                        title="Subscribe"
                      >
                        <span>{t('iscriviti')}</span>
                      </button>
                    </div>
                  </form>
                  <div className="form_link">
                    <div className="checkbox">
                      <input
                        onChange={(e) => {
                          Setprivacy_policy(!privacy_policy);
                        }}
                        checked={privacy_policy}
                        type="checkbox"
                        className="checkbox-box"
                      />
                      <p>
                        {' '}
                        <a
                          className="white_color"
                          href="https://www.iubenda.com/privacy-policy/55960526"
                          target="_blank"
                        >
                          {' '}
                          {t('common.privacy_policy')}
                        </a>
                      </p>
                      <p className="req_field float-right"> {t('campi_obbligatori')}</p> <br />
                    </div>
                    <p className="error">{privacy_policy === false ? 'Si prega di accettare la privacy' : ''}</p>
                  </div>
                  {/*<p className="tag_line">{t("siamo_a_tua_completa_disposizione")}</p>*/}

                  <div className="footer_dropdown">
                    <div className="styled-select">
                      <select defaultValue={localStorage.getItem('lang') || 'it'} onChange={handleLangChange}>
                        <option className="lang_option" value="it">
                          IT
                        </option>
                        <option className="lang_option" value="en">
                          EN
                        </option>
                      </select>
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright">
              <p>© {new Date().getFullYear()} Filmar S.p.A. - P.IVA 00650040983</p>
              <p className="page_link">
                <a href="https://www.iubenda.com/privacy-policy/55960526" target="_blank">
                  {t('common.privacy_policy')}
                </a>
                <a href="https://www.iubenda.com/privacy-policy/55960526/cookie-policy" target="_blank">
                  {t('common.cookie_policy')}
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Layout;
