import { React } from 'react';

const ProductImage = ({ activeimage, activeColor, product_t_images, product_image = [] }) => {
  // TODO: inline styles will be removed when we introduce css modules or an alternative to it
  const handleOnError = ({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = '/images/artic_06.jpg';
  };

  const imageStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  return (
    <div
      style={{
        width: 'min(130px, 100%)',
        height: '150px',
        backgroundColor: `${activeColor}`,
      }}
    >
      {activeimage?.length > 0 ? (
        <img style={imageStyles} src={activeimage} onError={handleOnError} alt="" />
      ) : activeColor && Array.isArray(product_t_images) && product_t_images?.length > 0 ? (
        <img
          style={{ ...imageStyles, mixBlendMode: 'soft-light' }}
          src={product_t_images[0]}
          onError={handleOnError}
          alt=""
        />
      ) : (
        <img style={imageStyles} src={product_image} onError={handleOnError} alt="" />
      )}
    </div>
  );
};

export default ProductImage;