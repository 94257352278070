import React from 'react';

import { FONDO_CONO_ID, TELLO_ID } from '../../../hooks/useSampleProducts';
import Tooltip from '../../shared/Tooltip/Tooltip';

import SampleImage from './SampleImage';

const Sample = ({ sample, handleSampleSelection, isSelected = false, selectedSubProducts = [], configid }) => {
  const renderSubProducts = (sample) => {
    if (!sample || !sample.subProducts) return null;

    return sample.subProducts.map((subProduct, idx) => (
        <label className="sample-subproduct" key={sample.codice_articolo + '-' + subProduct?.codice_articolo}>
          <div>
            <input
              type="checkbox"
              name="sample[]"
              value={subProduct?.codice_articolo}
              checked={selectedSubProducts.includes(subProduct.codice_articolo)}
              onChange={(e) => {
                handleSampleSelection(e.target.checked, sample.codice_articolo, subProduct.codice_articolo);
              }}
            />
            <span className="checkbox"></span>
          </div>
          <div title="Nome">
            {sample.codice_articolo === TELLO_ID ? subProduct?.description : null}
            {sample.codice_articolo === FONDO_CONO_ID ? subProduct?.nome_articolo : null}
          </div>
          {sample.codice_articolo === FONDO_CONO_ID ? (
            <>
              <div title="Nm">{subProduct?.nm}</div>
              <div title="Ne">{subProduct?.ne}</div>
              <div title="Fin">{subProduct?.finezza || 'NA'}</div>
            </>
          ) : null}
        </label>
    ));
  };

  const renderSampleContent = () => {
    return (
      <>
        <div className="sample-details">
          <div className="title-block">
            <span className="title">{sample.title[0].toUpperCase() + sample.title.substring(1).toLowerCase()}</span>
            {sample.tooltipDesc ? <Tooltip text={sample.tooltipDesc}></Tooltip> : null}
          </div>
          {sample.desc ? <div className="desc">{sample.desc}</div> : null}
        </div>
        <SampleImage sampleCodiceArticolo={sample?.codice_articolo} configid={configid} className="has-border" />
        {sample?.subProducts ? (
          <div className="sample-subproducts custom-scrollbar">{renderSubProducts(sample)}</div>
        ) : null}
      </>
    );
  };

  return sample?.subProducts?.length > 0 ? (
    <div className="block-campionatura">
    <div className="sample">{renderSampleContent()}</div>
    </div>
  ) : (
    <div className="block-campionatura">
    <label className="sample">
      <div>
        <input
          type="checkbox"
          name="sample[]"
          value={sample.codice_articolo}
          checked={isSelected}
          onChange={(e) => {
            handleSampleSelection(e.target.checked, sample.codice_articolo);
          }}
        />
        <span className="checkbox"></span>
      </div>
      {renderSampleContent()}
    </label>
    </div>
  );
};

export default Sample;