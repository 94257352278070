import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import useDebounce from './useDebounce'; // Assicurati che il percorso sia corretto

export const useUsersByAgent = () => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const filtersInitialState = {
    search: '',
  };
  const [filters, setFilters] = useState(filtersInitialState);

  const debouncedFilters = useDebounce(filters, 300);

  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);

  const updateFilter = (filter, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [filter]: value,
    }));
  };

  const resetFilter = () => {
    setFilters(filtersInitialState);
  };

  useEffect(() => {
    const { search } = debouncedFilters;

    const fetchUsersByAgent = async () => {
      const abortController = new AbortController();
      const signal = abortController.signal;
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get('/v1/get-users-by-agent', {
          params: {
            agent: dataUser.ACCOUNTNUM,
            query: search,
            page,
            perPage,
          },
          headers: {
            Authorization: `Bearer ${dataUser.token}`,
          },
          signal,
        });

        setRows(response?.data?.result?.data?.data || []);
        setTotalCount(response?.data?.result?.data?.total);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Error fetching users by agent:', error);
          setError(error);
          setRows([]);
        }
      } finally {
        setIsLoading(false);
      }

      return () => abortController.abort();
    };

    fetchUsersByAgent();
  }, [debouncedFilters, perPage, page, dataUser.ACCOUNTNUM, dataUser.token]);

  return {
    isLoading,
    rows,
    page,
    setPage,
    filters,
    updateFilter,
    perPage,
    setPerPage,
    resetFilter,
    totalCount,
    error,
  };
};