import React from 'react';
import { useTranslation } from 'react-i18next';

const LangLink = ({ to, className, children }) => {
  const { i18n } = useTranslation();

  return (
    <a className={className} href={`/${i18n.language}${to}`}>
      {children}
    </a>
  );
};

export default LangLink;