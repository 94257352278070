import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';
import useDebounce from './useDebounce';

export const useUserDocuments = ({ orderId, autoFilterUsingDebounce = false }) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const FEFiltersInitialState = {
    name: '',
    type: '',
  };
  const [FEFilters, setFEFilters] = useState(FEFiltersInitialState);

  const filtersInitialState = {
    orderId: orderId || '',
    startDate: '',
    endDate: '',
  };
  const [filters, setFilters] = useState(filtersInitialState);

  const [isLoading, setIsLoading] = useState(false);
  const [errorWhileLoading, setErrorWhileLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const debouncedFilters = useDebounce(filters, 300);

  const resetFilter = () => {
    if (filters.startDate || filters.endDate) {
      setFilters(filtersInitialState);
    }

    setFEFilters(FEFiltersInitialState);

    if (!autoFilterUsingDebounce) {
      fetchWithFilter(filtersInitialState);
    }
  };

  const updateFilter = (filter, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [filter]: value,
    }));
  };

  const updateFEFilter = (filter, value) => {
    setFEFilters((prevState) => ({
      ...prevState,
      [filter]: value,
    }));
  };

  const fetchWithFilter = (filtersToUse = null) => {
    const { orderId, startDate, endDate } = filtersToUse ? filtersToUse : filters;
    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);
    setErrorWhileLoading(false);

    axios
      .get('/v1/get-customer-documents', {
        params: {
          ...(orderId ? { orderid: orderId } : { customerid: dataUser.ACCOUNTNUM }),
          fromdate: startDate,
          todate: endDate,
        },
        headers: {
          Authorization: `Bearer ${dataUser?.token}`,
        },
        signal,
      })
      .then((res) => {
        setRows([
          ...(res?.data?.result?.data?.listOfPDFInvoices || []),
          ...(res?.data?.result?.data?.listOfPDFPackingSlips || []),
        ]);
      })
      .catch((error) => {
        console.error('error', error);
        setRows([]);
        setErrorWhileLoading(true);
      })
      .finally(() => setIsLoading(false));

    return () => abortController.abort();
  };

  // FirstRender
  useEffect(fetchWithFilter, []);

  // AutoFiltering
  useEffect(() => {
    if (!autoFilterUsingDebounce) {
      return;
    }

    return fetchWithFilter();
  }, [debouncedFilters, autoFilterUsingDebounce]);

  // FilterOnClick
  const handleFilterClick = () => fetchWithFilter();

  const filteredRows = useMemo(() => {
    return rows
      .filter((item) => {
        if (!FEFilters.name.length) {
          return true;
        }

        return item?.name?.toLowerCase()?.includes(FEFilters.name.toLowerCase());
      })
      .filter((item) => {
        if (!FEFilters.type.length) {
          return true;
        }

        return item?.document_type === FEFilters.type;
      });
  }, [rows, FEFilters]);

  return {
    isLoading,
    errorWhileLoading,
    rows,
    filteredRows,
    filters,
    updateFilter,
    FEFilters,
    updateFEFilter,
    resetFilter,
    handleFilterClick,
  };
};