import React from 'react';
import { useState } from 'react';

import './styles.scss';

const Tooltip = ({ text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="tooltip-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <img className="tooltip-icon" src="/images/information.png" alt="" />
      {showTooltip && <div className="tooltip">{text}</div>}
    </div>
  );
};

export default Tooltip;