import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enData from './constants/en.json';
import itData from './constants/it.json';

export const supportedLanguages = ['en', 'it'];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    supportedLngs: supportedLanguages,
    resources: {
      en: {
        translation: enData,
      },
      it: {
        translation: itData,
      },
    },
    detection: {
      order: ['path', 'localStorage'],
      lookupFromPathIndex: 0,
      lookupLocalStorage: 'lang',
    },
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
